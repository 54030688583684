import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';

import yandexDiskImg from './assets/images/yandex-disk.svg';
import zipImg from './assets/images/zip.svg';
import launcherImage from './assets/images/launcherImage.jpg';
import serverImage from './assets/images/serverImage.jpg';
import mapIcon from './assets/images/map.svg';

const Container = styled.div`
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
  //overflow-x: hidden;
`;

const HowToPlaySection = styled.section`
  width: 100%;
  min-height: 100vh; /* Изменено с height на min-height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #171717;
  position: relative;
  padding-top: 60px; /* Добавляем отступ сверху */

  @media (max-width: 768px) {
    padding: 40px 0;
    min-height: auto; /* Изменено с height на min-height */
  }
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-size: 35px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 2;
  font-family: 'Acrom', sans-serif;
  &:after {
    content: 'START';
    font-size: 150px; /* Вернем большой размер */
    color: rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 40px;

    &:after {
      font-size: 80px;
      top: 60%;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    &:after {
      font-size: 100px;
      top: 55%;
    }
  }
`;

const StepsContainer = styled.div`
  margin-top: 20px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

const StepTitle = styled.div`
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StepNumber = styled.div`
  background: #ea864d;
  box-shadow: 0 3px 25px rgb(170, 95, 52);
  border-radius: 100px 100px 0 100px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #171717;
  font-weight: 700;
  margin-right: 10px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 12px;
  }
`;

const StepDescription = styled.div`
  margin-top: 15px;
  font-size: 20px;
  font-weight: 300;
  color: #e2e2e2;
  max-width: 600px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const StepButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainButton = styled.a`
  display: inline-block;
  padding: 15px 25px;
  background: #ea864d;
  color: #171717;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    box-shadow: 0 0 30px #ea864d;
    color: #171717;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const IconButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const IconButton = styled.a`
  margin-left: 10px;
  width: 25px;
  height: 25px;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(0.9);
    opacity: 1;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`;

const MapLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const MapLink = styled(RouterLink)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 25px;
  font-weight: 600;
  color: #e2e2e2;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;

  img {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }

  &:hover {
    text-shadow: 0 2px 20px white;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const StepContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 548px;
  max-width: 548px;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ImageWrapperRight = styled.div`
  flex: 0 0 505px;
  max-width: 505px;
  margin-left: 20px;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 20px;
    max-width: 100%;
  }
`;

const TextWrapperRight = styled.div`
  flex: 1;
  max-width: 600px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
`;

const MobileMessage = styled.div`
  color: red;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  background-color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 15px;
  }
`;

const HowToStart = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    AOS.init();
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(/android|ipad|iphone|ipod|mobile/i.test(userAgent.toLowerCase()));
  }, []);

  return (
    <HowToPlaySection>
      <Container>
        {isMobile ? (
          <MobileMessage>Установка доступна только на ПК</MobileMessage>
        ) : (
          <>
            <Title>КАК НАЧАТЬ ИГРАТЬ</Title>
            <StepsContainer>
              <Step data-aos="fade-right">
                <StepContent>
                  <ImageWrapper>
                    <img src={launcherImage} alt="Launcher" />
                  </ImageWrapper>
                  <TextWrapper>
                    <StepTitle>
                      <StepNumber>1 шаг</StepNumber>
                      УСТАНАВЛИВАЕМ ЛАУНЧЕР
                    </StepTitle>
                    <StepDescription>
                      Начать играть проще, чем ты думал! <br />
                      Первым делом качаем лаунчер, <b>именно он</b> установит последнюю версию игры
                    </StepDescription>
                    <StepButtons>
                      <MainButton href="/PremierGameInstall.exe" download>
                        Скачать лаунчер
                      </MainButton>
                      <IconButtonsWrapper>
                        <IconButton href="https://yadi.sk/d/ZLTpXH3CFWSPlw">
                          <img src={yandexDiskImg} alt="Yandex Disk" />
                        </IconButton>
                        <IconButton href="https://yadi.sk/d/DmrhkbI9dnkyBg">
                          <img src={zipImg} alt="Архив игры" />
                        </IconButton>
                      </IconButtonsWrapper>
                    </StepButtons>
                  </TextWrapper>
                </StepContent>
              </Step>
              <Step data-aos="fade-left">
                <StepContent>
                  <TextWrapperRight>
                    <StepTitle>
                      <StepNumber>2 шаг</StepNumber>
                      ПОДКЛЮЧАЕМСЯ К СЕРВЕРУ
                    </StepTitle>
                    <StepDescription>
                      Перед подключением на сервер, необходимо придумать Role Play имя Вашего персонажа, оно должно иметь формат{' '}
                      <b>Name_Surname</b> (Leonid_Ershov). <br />
                      <br />
                      Также, Вы можете ознакомиться с картой проекта:
                    </StepDescription>
                    <MapLinkContainer>
                      <MapLink to="/map">
                        <img src={mapIcon} alt="Map Icon" />
                        КАРТА ГОРОДА
                      </MapLink>
                    </MapLinkContainer>
                  </TextWrapperRight>
                  <ImageWrapperRight>
                    <img src={serverImage} alt="Server" />
                  </ImageWrapperRight>
                </StepContent>
              </Step>
            </StepsContainer>
          </>
        )}
      </Container>
    </HowToPlaySection>
  );
};

export default HowToStart;
