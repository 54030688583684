import React from 'react';
import styled from 'styled-components';
import { FaBriefcase } from 'react-icons/fa';
import { GrUserWorker } from 'react-icons/gr';
import backgroundImage from './assets/images/background.jpg'; // Импортируем фоновое изображение

// Стиль для контейнера вакансий с фоновым изображением
const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Высота на всю страницу */
  padding: 20px;
  font-family: 'Montserrat', sans-serif; /* Подключение шрифта Montserrat */
  background: url(${backgroundImage}) center/cover no-repeat; /* Устанавливаем фоновое изображение */
  background-size: cover; /* Обеспечиваем, чтобы фон покрывал всю область */
  color: #fff; /* Цвет текста по умолчанию белый */
`;

// Стиль для заголовка страницы
const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d; /* Оранжевый цвет заголовка */
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-top: 100px;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const JobCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column; /* Изменяем направление на вертикальное для мобильных устройств */
    align-items: center; /* Центрируем карточки */
  }
`;

const JobCard = styled.div`
  background: rgba(23, 23, 23, 0.9);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 48%;
  text-align: left;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 90%; /* Увеличиваем ширину карточки на мобильных устройствах */
  }
`;

const JobInfo = styled.div`
  display: flex;
  align-items: center; /* Центрируем элементы по вертикали */
  justify-content: flex-start; /* Выравниваем элементы влево */
  margin-bottom: 10px; /* Отступ снизу */
`;

const JobTitle = styled.h2`
  color: rgb(234, 134, 77); /* Оранжевый цвет заголовка вакансии */
  margin: 0;
  text-align: center; /* Находится в центре */
  flex-grow: 1; /* Позволяет заголовку занять доступное пространство */
`;

const JobIcon = styled.div`
  font-size: 40px; /* Размер иконки */
  color: #ea864d; /* Цвет иконки */
  margin-right: 10px; /* Отступ справа для иконки */
`;

const JobDescription = styled.div`
  flex-grow: 1; /* Позволяет тексту занимать всё доступное пространство */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Центрирование кнопки */
  margin-top: 20px; /* Отступ сверху для кнопки */
`;

const ApplyButton = styled.button`
  background-color: rgba(234, 134, 77, 0.8); /* Оранжевая кнопка с прозрачностью */
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;

  &:hover {
    background-color: #d97842; /* Более темный оранжевый при наведении */
    transform: scale(1.05); /* Легкая анимация при наведении */
  }
`;

// Стили для подзаголовков и пунктов
const SectionTitle = styled.h3`
  color: #ea864d; /* Оранжевый цвет для подзаголовков */
  margin-top: 20px;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  margin: 5px 0;
  color: #fff; /* Белый текст для пунктов списка */
`;

const Jobs = () => {
  return (
    <JobsContainer>
      <Title>Вакансии</Title> {/* Заголовок страницы */}
      <JobCardsContainer>
        <JobCard>
          <JobInfo>
            <JobIcon><FaBriefcase /></JobIcon>
            <JobTitle>Администратор сервера</JobTitle>
          </JobInfo>
          <JobDescription>
            <SectionTitle>Преимущества работы в администрации:</SectionTitle>
            <ul>
              <ListItem>Вы будете работать в команде, где каждый готов поддержать и помочь в решении вопросов. Вместе мы создаем комфортную и дружелюбную атмосферу на сервере.</ListItem>
              <ListItem>За вашу работу вы получите вознаграждение в виде зарплаты администратора и донат-бонусов, которые можно использовать для приобретения внутриигровых привилегий и возможностей.</ListItem>
              <ListItem>Административная работа на сервере позволяет развивать навыки управления, решения конфликтов и работы в команде. Это ценный опыт, который пригодится вам в жизни.</ListItem>
              <ListItem>Ваше участие будет иметь реальное значение для проекта. Вы сможете вносить предложения и идеи, которые помогут сделать сервер лучше и интереснее для всех игроков.</ListItem>
              <ListItem>Став администратором, вы получите признание и уважение среди игроков и команды проекта, ведь на ваших плечах будет лежать ответственность за поддержание порядка и развитие сервера.</ListItem>
            </ul>

            <SectionTitle>Минимальные требования:</SectionTitle>
            <ul>
              <ListItem>Уверенное знание правил: Вы должны хорошо знать правила сервера, чтобы эффективно поддерживать порядок.</ListItem>
              <ListItem>Возраст: Вам должно быть не менее 16 лет (возможны исключения).</ListItem>
              <ListItem>Работающий микрофон: Для общения с игроками и командой.</ListItem>
              <ListItem>Минимальная активность: Требуется отыгрывать минимум 3 часа в день.</ListItem>
            </ul>
          </JobDescription>
          <ButtonContainer>
            <ApplyButton>Оставить заявку</ApplyButton>
          </ButtonContainer>
        </JobCard>

        <JobCard>
          <JobInfo>
            <JobIcon><GrUserWorker /></JobIcon>
            <JobTitle>Лидер фракции</JobTitle>
          </JobInfo>
          <JobDescription>
            <SectionTitle>Преимущества работы в роли лидера фракции:</SectionTitle>
            <ul>
              <ListItem>Уникальная возможность управления: Как лидер, вы будете руководить одной из ключевых фракций на сервере, будь то государственная структура или преступная группировка. Это позволит вам развивать лидерские качества и навыки стратегического мышления.</ListItem>
              <ListItem>Влияние на игровой процесс: Вы сможете вносить предложения и идеи, которые помогут сделать игру более интересной и динамичной для всех участников. Ваши решения будут иметь реальное значение для развития фракции и всего сервера.</ListItem>
              <ListItem>Командная работа: Работая в команде, вы будете взаимодействовать с другими игроками, что способствует созданию дружелюбной и поддерживающей атмосферы. Это также поможет вам развивать навыки общения и сотрудничества.</ListItem>
              <ListItem>Признание и уважение: Ваша роль будет высоко оценена как среди членов вашей фракции, так и среди других игроков. Вы получите признание за свои достижения и вклад в развитие сервера.</ListItem>
            </ul>

            <SectionTitle>Минимальные требования:</SectionTitle>
            <ul>
              <ListItem>Лидерские качества: Вы должны обладать способностью принимать решения и вести за собой команду.</ListItem>
              <ListItem>Ответственность: Важно быть ответственным за действия своей фракции и поддерживать порядок в соответствии с правилами сервера.</ListItem>
              <ListItem>Активность: Требуется регулярное участие в игре, чтобы поддерживать активность фракции и взаимодействовать с другими игроками.</ListItem>
              <ListItem>Возраст: Вам должно быть не менее 16 лет (возможны исключения).</ListItem>
              <ListItem>Работающий микрофон: Для эффективного общения с членами фракции и другими игроками.</ListItem>
            </ul>
          </JobDescription>
          <ButtonContainer>
            <ApplyButton>Оставить заявку</ApplyButton>
          </ButtonContainer>
        </JobCard>
      </JobCardsContainer>
    </JobsContainer>
  );
};

export default Jobs;